










































































































import { Veiculo } from '@/core/models/geral';
import { EmpresaService, SituacaoVeiculoService, VeiculoHistoricoService, VeiculoService } from '@/core/services/geral';
import { PageBase } from '@/core/services/shared';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class ListaVeiculo extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogHistorico: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:"actions" ,sortable: false },
    { text: 'Placa', value: 'placa' },
    { text: 'Chassi', value: 'chassi' },
    { text: 'Empresa', value: 'empresa.nome' },
    { text: 'Atualizado', value: 'dataHora' },
    { text: 'Prêmio', value: 'premio' },
    { text: 'Situacação', value: 'situacao.nome' },
  ];
  item = new Veiculo();
  service = new VeiculoService();
  historicoService = new VeiculoHistoricoService();
  sheet: boolean = false;
  filtro: any = {
    placa: '',
    chassi: '',
    empresaId: null,
    situacaoId: null
  }
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  empresas: any[] = []
  situacaoVeiculos: any[] = [];
  dialogInforme: boolean = false;
  teste = {
    placa: "",
    chassi: "",
    batalhao: "",
    marca: "",
    modelo: "",
    cor: "",
    link: ""
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.service.MontaFiltro(this.filtro), "Empresa, Situacao").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  mounted() {
    const empresaService = new EmpresaService();
    empresaService.ListarTudo().then(
      res=>{
        this.empresas = res.data.items;
        this.empresas.unshift({
          id: null,
          nome: ''
        })
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    )

    const situacaoVeiculoService = new SituacaoVeiculoService();
    situacaoVeiculoService.ListarTudo().then(
      res=>{
        this.situacaoVeiculos = res.data.items;
        this.situacaoVeiculos.unshift({
          id: null,
          nome: ''
        })
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    )
  }

  AbrirDialogCadastro(item?: Veiculo){
    if(item){
      this.service.ObterPorId(item.id, "Empresa, Situacao").then(
        res=>{
          this.item = res.data
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    else{
      this.item = new Veiculo();
      this.dialogCadastro = true;
    }
  }

  AbrirDialogHistorico(item: Veiculo){

    this.service.ObterPorId(item.id).then(
      res=>{
        this.item = res.data
        this.dialogHistorico = true;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
  }

  AbrirDialogInforme(item: Veiculo) {
    
    let historico;

    this.service.UltimoHistorico(item.placa, item.chassi).then(
      res => {
        historico = res.data;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.teste = {
        placa: item.placa,
        chassi: item.chassi,
        batalhao: historico.localRoubo? historico.localRoubo.batalhao? historico.localRoubo.batalhao.nome : '' : '',
        marca: item.marca,
        modelo: item.modelo,
        cor: item.cor,
        link: historico.localizacaoLink
      }

      this.dialogInforme = true;
    })
  }

  Excluir(item: Veiculo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id)
          .then(res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          this.$swal("Aviso", result.value, "success");
          this.Atualizar();
        }
      })
  }
}

